<template>
  <div class="d-flex flex-row align-center">
    <v-checkbox v-if="data.delivery_status == 'pending'" :class="{ 'checkbox': !ableToDispatch }" @change="toggleSelected"
      v-model="selected" :disabled="disabledSelect" />
    <itemCard :data="computedData" :dateValue="arrivalDate" :minSelectDate="deliveryDate" :options="driverList"
      :selectOptionValue="data.delivery_man_id" :updatedResState="updatedResState" @action="action"
      :key="new Date().getTime()" />
  </div>
</template>

<script>
import listItem from "@/components/liffList/listItem.js";
import listRow from '@/components/list/mixins/listRow';
import deliveryCardItemMixins from "@/mixins/deliveryCardItem.js"

import Moment from 'moment'
import { mapState, mapGetters } from 'vuex'

export default {
  mixins: [listItem, listRow, deliveryCardItemMixins],
  data: () => {
    return {
      useData: true,
      componentKey: 0,
      updatedResState: 0,
    }
  },
  computed: {
    ...mapState({
      driverList(state) {
        const driverListData = state.list[this.listKey].driverList ? state.list[this.listKey].driverList.data : []
        const thirdPL = {
          id: null,
          name: "第三方貨運"
        }
        if (!driverListData) {
          return []
        }
        const data = [...driverListData, thirdPL]
        return data
      }
    }),
    ...mapGetters({
      providerId: `member/providerId`,
      // hasSelectFeature: `list/${this.listKey}/hasSelectFeature`
    }),
    ableToDispatch() {
      if (this.hasSelectFeature && this.data.arrival_at) {
        return true
      }
      return false
    },
    deliveryDate() {
      if (!this.data.delivery_at) {
        return Moment().format('YYYY-MM-DD')
      } else {
        return this.data.delivery_at
      }
    },
    arrivalDate() {
      // if (!this.data.arrival_at) {
      //   return Moment().format('YYYY-MM-DD')
      // } else {
      return this.data.arrival_at
      // }
    },
    hasSelectFeature() {
      return this.$store.getters[`list/${this.listKey}/hasSelectFeature`]
    },
    computedData() {
      return [
        {
          text: "訂單編號",
          value: this.data.order_no,
        },
        {
          text: "地址",
          value: this.data.address,

        },
        {
          text: "出貨日期",
          value: this.data.delivery_at ? this.$helper.orderTimeFormat(this.data.delivery_at) : '-',
          class: this.statusColorClass,
        },

      ];
    },
    listActions() {
      return this.$store.getters[`list/${this.listKey}/actions`]
    },
  },
  methods: {
    async action(object) {
      this.$store.dispatch('loading/active')
      const updateData = {
        delivery_man_id: object.selectedValue,
        arrival_at: object.selectedDateValue
      }
      const res = await this.$api.collection.providerApi.updateDeliver(this.providerId, this.data.order_no, updateData)
      if (res == 'Ok') {
        this.updatedResState = 1
      } else {
        updatedResState = 2
      }

      this.listActions.index().then(() => {
        this.componentKey += 1
        setTimeout(() => {
          this.updatedResState = 0
        }, 1000);
      });

    },
  },
  mounted() {
  }
}
</script>

<style lang="scss">
.checkbox {
  visibility: hidden;
}
</style>